// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$credible-finance-web-primary: mat.define-palette(mat.$indigo-palette);
$credible-finance-web-accent: mat.define-palette(mat.$pink-palette,
        A200,
        A100,
        A400);

// The warn palette is optional (defaults to red).
$credible-finance-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$credible-finance-web-theme: mat.define-light-theme((color: (primary: $credible-finance-web-primary,
                accent: $credible-finance-web-accent,
                warn: $credible-finance-web-warn,
            ),
        ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($credible-finance-web-theme);



body {
    margin: 0;
    padding: 0;
    background-color: #17181C;
    font-family: "Courier Prime", monospace !important;
}

html {
    overscroll-behavior: none;
    overflow-x: hidden;
}

html,
body {
    height: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

// hides the auto fill blue background from chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

// hides the input type number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

table {
    margin-top: 0rem;
    margin-bottom: 0rem;
    width: 100%;

    th {
        text-transform: uppercase;
        font-weight: bold;
        line-height: 2rem;
        font-size: 0.9rem;
        text-align: left;
        padding: 0.5rem 1rem;
    }

    td {
        text-align: left;
        font-size: 0.9rem;
        line-height: 2.5rem;
        padding: 0.4rem 0.25rem;
        font-weight: normal;
    }
}

.dark {
    table {
        th {
            background-color: #1D1E22;
            color: grey;
        }

        td {
            background-color: transparent;
            color: white;
        }

        tr:hover {
            background: rgb(41, 42, 47);
        }

        tr:not(:last-child) {
            td {
                border-bottom: 0.1px solid rgba(192, 201, 213, 0.5);
            }
        }
    }
}

.light {
    table {
        th {
            background-color: #FBFCFD;
        }

        td {
            background-color: transparent;
            color: grey;
        }

        tr:hover {
            background: lightgrey;
        }

        tr:not(:last-child) {
            td {
                border-bottom: 0.1px solid rgba(192, 201, 213, 0.5);
            }
        }
    }
}

.mat-expansion-panel-header-title {
    font-size: 15px !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
    border-radius: 2px;
    display: grid;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
}

.hv-modal {
    background-color: transparent !important;
}

@media (max-height: 720px) {
    .hv-modal-content {
        max-height: 80vh !important;
        left: 58% !important;
    }
}

.loader {
    height: 50vh;
    text-align: center;
    align-items: center;
    display: grid;
    width: max-content;
    align-content: center;
    margin: auto;
}

.no-data {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    color: gray;
    font-size: 16px;
}

.mat-mdc-dialog-content {
    max-height: 100vh !important;
    overflow-y: auto;
}

.tooltip-img {
    width: 14px;
    height: 14px;
    margin-left: 0.5rem;
}

:root {
    --d: 10000ms;
    --angle: 90deg;
    --gradX: 100%;
    --gradY: 50%;
    --c1: #cf0;
    --c2: rgba(168, 239, 255, 0.1);
    --bs-body-font-weight: 300 !important;
}

@property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: true;
}

@property --gradX {
    syntax: '<percentage>';
    initial-value: 50%;
    inherits: true;
}

@property --gradY {
    syntax: '<percentage>';
    initial-value: 0%;
    inherits: true;
}

// hides the reCAPTCHA badge at the bottom right corner
.grecaptcha-badge {
    width: 0 !important;
    height: 0 !important;
}

#telegram-login-crediblefinancebot {
    width: 0;
    height: 0;
}